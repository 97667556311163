.ButtonMultyColor {
    border: none;
    border-radius: 50px;
    color: #fff;
    display: flex;
    padding: 0.5rem 3rem;
    background: linear-gradient(270deg, #13ADC7 0%, #945DD6 100%);
    transition: .4s ease;
    font-size: 2rem;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}

.ButtonMultyColor:focus {
    outline: none;
}

.ButtonMultyColor:active {
    opacity: 1;
    box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}

.ButtonMultyColor:disabled {
    background: linear-gradient(270deg, #00DBD8 0%, #B133FF 100%);
    opacity: 0.5;
    box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}