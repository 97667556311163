h5 {
    font-size: 2.3rem;
    font-weight: 800;
    background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.40) 60.15%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
    h5 {
        font-size: 1.9rem;
    }
}

@media screen and (max-width: 350px) {
    h5 {
        font-size: 1.3rem;
    }
}