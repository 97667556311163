.ButtonMultiplyColorHref {
  border: none;
  border-radius: 50px;
  color: #fff;
  display: flex;
  left: 0;
  padding: 0.5rem 1rem;
  width: 35%;
  background: linear-gradient(270deg, #13ADC7 0%, #945DD6 100%);
  transition: .4s ease;
  font-size: 2rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: inset 0px 0px 4px rgba(20, 20, 55, 0.3);
  text-decoration: none;
}

.ButtonMultiplyColorHref:focus {
    outline: none;
}

.ButtonMultiplyColorHref:active {
    opacity: 1;
    box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}

@media screen and (max-width: 950px) {
    .ButtonMultiplyColorHref {
        width: 50%;
    }
}

@media screen and (max-width: 550px) {
    .ButtonMultiplyColorHref {
        font-size: 1.5rem;
    }
}