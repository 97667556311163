.ButtonVisitSite {
    border: 1px solid #D1DFED;
    padding: 1rem 2rem;
    text-decoration: none;
    color: #D1DFED;
    font-size: 1.3rem;
    font-weight: 300;

    transition: all 0.2s ease-in;
}

.ButtonVisitSite:hover {
    background-color: var(--dark-blue);
    border: 1px solid var(--dark-blue);
}