.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 8rem;
    margin-top: 5rem;
}

.footer-holder {
    border-top: 1px solid rgba(255, 255, 255, 0.40);
    width: 90%;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;;
}

.footer-first-row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footer-mail {
    color: #3C4E8C;
    font-size: 1.2rem;
    font-weight: 500;
}

.footer-mail-text {
    color: rgba(255, 255, 255, 0.40);
    font-weight: 300;
}

.footer-second-row {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.copy-text {
    font-size: 1rem;
}

.top-bar-icons {
    border-radius: 40%;
    font-size: 2.3rem;
    padding: 0.5rem;
    transition: all 0.3s ease-in
}

.top-bar-icons:hover {
    cursor: pointer;
    background-color: #73788c;
}