.switch {
  position: relative;
  display: inline-block;
  width: 4.3rem;
  height: 2rem;
  margin: 0 10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #73788c;
  transition: 0.4s;
  border-radius: 34px;
}

.switch input {
  display: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4rem;
  width: 1.4rem;
  left: 4px;
  bottom: 5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50px;
}

.top-bar-right input:checked + .slider {
  background-color: #3c4e8c;
}

.top-bar-right input:checked + .slider:before {
  transform: translateX(2.25rem);
}
