.downCV {
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloadBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
    font-weight: 300;
    text-decoration: none;
    padding: 1rem 2rem;
    border-radius: 30px;
    background-color: #fff;
    color: var(--dark-blue);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


@media screen and (max-width: 330px) {
    .downloadBtn {
        gap: 0.2rem;
    }
}